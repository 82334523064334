import { useRef } from "react"
import { usePathData } from "@/lib/contexts/appContext"
import SearchForm from "@/common/search-form/search-form"
import Block from "@/components/wrappers/block"
import styles from "./products-header.module.css"
import Breadcrumbs from "@/components/base/breadcrumbs/breadcrumbs"
import cx from "classnames"

export default function ArchiveHeader({ block }) {
  const { template } = usePathData()
  const { heading, search, description } = block.attrs
  const searchPanel = useRef()

  if (template !== "archive") {
    return null
  }

  return (
    <Block block={block} width="bleed" gap="none" className={styles.wrapper}>
      <Breadcrumbs gap="none" width="full" type="large" />
      <div className={styles.content}>
        <h1>{heading}</h1>
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
      <div className={cx(styles.form)} ref={searchPanel}>
        <SearchForm data={search} expand={block.attrs.expand} />
      </div>
    </Block>
  )
}
